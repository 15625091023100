<template>
  <div>
    <!-- filter start-->
    <div class="card-filter-section d-flex align-items-center justify-content-between mt-0">
      <p class="task-timesheet m-0">
        Tag
      </p>
      <div class="role-buttons d-flex flex-wrap align-items-center">
        <!-- add tag button  -->
        <table-button
          v-b-toggle.addtag
          btn-name="add tag"
        />
        <!-- add tag button  -->
        <Help
          v-show="$store.state.app.userManualList"
          code="Tag"
        />
      </div>
    </div>
    <div class="card-filter-section">
      <div class="d-flex align-items-center flex-wrap justify-content-between">
        <div class="d-flex align-items-center justify-content-start flex-wrap">
          <p class="filter-title">
            Filters:
          </p>

          <div class="">
            <custom-dropdown
              v-model="is_active"
              :options="isActiveList"
              :search-box="false"
              :hide-close="true"
              label="Active"
              icon="EditIcon"
            />
          </div>
          <option-drop-down v-model="perPage" />
        </div>
        <div class="d-flex align-items-center">
          <b-input-group
            class="input-group-merge search-project"
            style="min-width: 250px; max-width: 250px; width: 250px"
          >
            <b-form-input
              v-model="debouncedSearch"
              placeholder="Search by Tag Name"
            />

            <b-input-group-append is-text>
              <feather-icon
                v-if="debouncedSearch"
                icon="XIcon"
                @click="debouncedSearch = null,search = null"
              />
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>

          <div class="ml-1">
            <button
              class="apply-filter filterRedesign mr-1"
              @click="getTicketTagData()"
            >
              Apply Filter
            </button>
            <button
              class="apply-filter-clear filterRedesign"
              @click="clearFilter"
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--filter end-->

    <div class="horizontal-line table">
      <!--Table start from here-->
      <table-loader
        v-if="loading"
        :fields="fields"
      />
      <b-row v-else>
        <b-col cols="12">
          <b-table
            striped
            hover
            responsive
            :items="ticketTags"
            :fields="fields"
            :sort-by.sync="sortBy1"
            :sort-desc.sync="sortDesc1"
            show-empty
            class="scrollable-element user-table-items  tableDesigns mastertblColor table-responsive"
          >
            <template #cell(name)="data">
              <div class="media centeralign">
                <div class="media-aside align-self-center">
                  <b-avatar
                    :text="data.item.name.charAt(0)"
                    :style="{ 'background-color': '#' + data.item.color }"
                    size="40"
                  />
                </div>
                <div>
                  <p
                    v-b-tooltip.hover.top="data.item.name"
                    class="custom-project"
                    :style="{ color: '#' + data.item.color }"
                  >
                    {{ data.item.name }}
                  </p>
                </div>
              </div>
            </template>
            <template #cell(active)="data">
              <b-form-checkbox
                v-model="data.item.is_active"
                class="custom-control-success"
                name="check-button"
                size="md"
                switch
                @change="changeStatus(data.item, data.item.is_active)"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </template>
            <template #cell(action)="data">
              <span>
                <feather-icon
                  v-b-tooltip.hover.top="'Edit'"
                  icon="EditIcon"
                  role="button"
                  class="mr-1 text-dark"
                  size="18"
                  @click="edit(data.item)"
                />

                <feather-icon
                  v-b-tooltip.hover.top="'Delete'"
                  icon="Trash2Icon"
                  role="button"
                  class="text-danger"
                  size="18"
                  @click="deleteConfirmation(data.item.name)"
                />
              </span>
            </template>
          </b-table>
          <img
            v-if="totalCount === 0"
            src="../../assets/images/nodatafound/nodata.svg"
            alt="no-data"
            class="no-data-img"
          >

        </b-col>
        <!-- data table end  here -->
      </b-row>
      <!-- pagination section start -->
      <Pagination
        v-if="totalCount > 0 && !loading"
        :current-page="currentPage"
        :per-page="perPage"
        :total-count="totalCount"
        @currentPage="currentPage = $event"
      />
      <!-- pagination section end -->

      <!-- from start -->
      <tag-table-form
        ref="TagTableForm"
        @tagList="getTicketTagData"
      />

      <!-- form complete here -->
    </div>
  </div>
</template>

<script>
import customDropdown from '@/components/dropdown/customDropdown.vue'
import debounce from 'lodash/debounce'
import TableButton from '../../@core/components/buttons/TableButton.vue'
import TagTableForm from '../../@core/components/table-from/TagTableForm.vue'

export default {
  name: 'TagTable',
  components: {
    TableButton,
    TagTableForm,
    customDropdown,
  },

  data() {
    return {
      // filter
      search: null,
      tagType: null,

      tabs: 0,
      id: 'tag',
      type: null,
      totalCount: 0,
      currentPage: 1,
      perPage: 10,
      loading: true,
      sortBy: null,
      sortDesc: null,
      is_active: 'active',
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
          thStyle: { width: '200px' },
          tdStyle: { width: '200px' },
        },
        {
          key: 'active',
          label: 'Active',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'action',
          label: 'Action',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '120px' },
          tdStyle: { width: '120px' },
        },
      ],
      items: [],
      ticketTags: [],
      sortBy1: null,
      sortDesc1: null,
    }
  },
  computed: {
    debouncedSearch: {
      get() {
        return this.search
      },
      set: debounce(function (value) {
        this.search = value
        this.getTicketTagData()
      }, 750),
    },
  },
  watch: {
    perPage() {
      this.getTicketTagData()
    },
    currentPage: {
      handler(value) {
        if (value) {
          this.getTicketTagData()
        }
      },
    },
  },
  mounted() {
    this.getTicketTagData()
  },
  methods: {
    /**
     * @description For Edit tag color of tag to tagTableForm
     * @emits edit-tag
     */
    edit(data) {
      // to emit the event
      this.$refs.TagTableForm.id = true
      this.$refs.TagTableForm.name = data.name
      this.$refs.TagTableForm.type = data.type ?? 'projectType'
      this.$refs.TagTableForm.tagColor = data.color ? `#${data.color}` : null
      this.$refs.TagTableForm.code = data.code ? data.code : null
      this.$root.$emit('bv::toggle::collapse', 'addtag')
    },

    async getTicketTagData(page) {
      this.loading = true

      this.currentPage = page || this.currentPage
      const input = {
        page: this.currentPage,
        per_page: this.perPage,
        sort_field: this.sortBy1,
        sort_order: this.sortDesc1 ? 'asc' : 'desc',
        is_active: this.is_active && this.is_active !== 'all_data'
          ? this.is_active === 'active'
          : '',
        search: this.search,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/tag',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.totalCount = data.count
        this.ticketTags = data.tags
      }
      this.loading = false
    },

    /**
     *  Change Tag status
     * @param {index}  array index
     * @param {value}  value (true, false)
     * @returns if change then call updatestatus method
     */
    async changeStatus(index) {
      const tag = this.ticketTags.find(tag => tag === index)

      if (!tag) {
        return
      }
      const label = tag.is_active ? 'Active' : 'Deactive'
      const title = `Are you sure ${label} Tag?`
      const conformation = await this.conformationAlert(false, title, label)

      if (conformation.isConfirmed === true) {
        this.updateStatus(tag)
      } else {
        this.ticketTags = []
        this.getTicketTagData()
      }
    },
    /**
     *  Update Tag status
     * @method POST
     * @param {data} data(Tags Infomation in Array)
     * @returns Tag Status update
     */
    async updateStatus(data) {
      const response = await this.getHTTPPostResponse(
        `admin/master/tag/status/${data.name}?active=${data.is_active ? 1 : 0}`,
        {},
        true,
      )
      if (response && response.status === 200) {
        this.getTicketTagData()
      }
    },

    /**
     *  confirmation to delete tag
     * @param {id} tag id
     * @returns if conform to delete call deleteTag function
     */
    async deleteConfirmation(id) {
      const value = await this.conformationAlert(true)
      if (value.isConfirmed === true) {
        this.deleteTag(id)
      }
    },
    /**
     *  delete tag
     * @method DELETE
     * @param {id}  tag name
     * @returns delete tag
     */
    async deleteTag(id) {
      const response = await this.getHTTPDeleteResponse(
        `admin/master/tag/delete/${id}`,
        {},
        true,
      )
      if (response && response.status === 200) {
        this.getTicketTagData()
      }
    },
    clearFilter() {
      this.tagType = null
      this.is_active = 'active'
      this.perPage = 10
      this.search = null
      this.getTicketTagData()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/component-css/masterSettingFilter.scss";
</style>
