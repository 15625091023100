<template>
  <div>
    <validation-observer ref="tagObserver">
      <b-sidebar
        id="addtag"
        ref="tagForm"
        bg-variant="white"
        right
        backdrop
        shadow
        class="sidebar-form"
        @hidden="sidebarToggle"
      >
        <h3 class="form-title">
          {{ id ? "Edit" : "Add" }} Tag
        </h3>
        <div class="line user-underline" />
        <b-form-group
          label="Tag name"
          label-for="tagname"
          class="required-form-filed"
        >
          <validation-provider
            #default="{ errors }"
            name="Tag name"
            rules="required"
          >
            <b-form-input
              id="name"
              v-model="name"
              :state="errors.length > 0 ? false : null"
              placeholder="Tag Name"
              :readonly="id"
            />
            <small
              v-if="errors[0]"
              class="text-danger"
            >{{ errors[0] }}</small>
            <small
              v-else
              class="text-danger"
            >{{ error }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Tag color -->
        <b-form-group
          label="Tag color"
          label-for="Tag  color"
          class="required-form-filed"
        >
          <validation-provider
            #default="{ errors }"
            name="Color"
            mode="passive"
            rules="required"
          >
            <b-input-group class="color-picker-input">
              <b-form-input
                id="tagcolor"
                v-model="tagColor"
                type="text"
                name="tagcolor"
                placeholder="Select any color"
              />
              <b-input-group-append is-text>
                <input
                  v-model="tagColor"
                  type="color"
                  name="projectcolor"
                  class="color-picker py-0"
                >
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!--TagColor End-->
        <template #footer>
          <div class="form-button d-flex my-2">
            <button
              class="btn side-bar-btn"
              :disabled="btnLoader"
              @click.prevent="validationForm"
            >
              <b-spinner
                v-if="btnLoader"
                small
                class="spinner"
              />
              {{ id ? "Update" : "Save" }}
            </button>
            <button
              class="btn side-bar-btn"
              @click="clearData"
            >
              Clear
            </button>
          </div>
        </template>
      </b-sidebar>
    </validation-observer>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BSidebar,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'TagTableFrom',
  components: {
    BFormGroup,
    BFormInput,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
  },
  data() {
    return {
      required,
      id: null,
      name: null,
      tagColor: null,
      error: null,
      type: 'timesheet',
      btnLoader: false,
      code: null,
    }
  },

  mounted() {
    this.clearData()
  },
  methods: {
    /**
     * clear from detail
     */
    sidebarToggle() {
      this.id = null
      this.name = null
      this.tagColor = null
      this.$refs.tagObserver.reset()
    },
    /**
     * check the form validation
     * @returns if success true then call saveTagDtl Method
     */
    validationForm() {
      this.$refs.tagObserver.validate().then(success => {
        if (success) {
          this.saveTagDtl()
        }
      })
    },
    /**
     * Add & Update the tagTableform details & call tag listing in tagtable
     * @emits tagList
     * @returns projectUser detail add & update
     */
    async saveTagDtl() {
      this.btnLoader = true
      const color = this.tagColor ? this.tagColor.substring(1) : null
      const input = {
        name: this.name,
        color,
        code: this.code,
      }
      let response = null

      if (this.id) {
        response = await this.getHTTPPutResponse(
          `admin/master/tag/update/${this.name}`,
          input,
          true,
        )
      } else {
        response = await this.getHTTPPostResponse(
          'admin/master/tag/create',
          input,
          true,
        )
      }

      if (response && response.status === 200) {
        this.$root.$emit('bv::toggle::collapse', 'addtag')
        this.$emit('tagList', 1)
        setTimeout(() => {
          this.btnLoader = false
        }, 200)
      }
      this.btnLoader = false
    },

    /**
     * Clear form details
     */
    clearData() {
      this.name = null
      this.tagColor = null
      this.$refs.tagObserver.reset()
    },
  },
}
</script>

<style lang="scss">
.custom-radio {
  .custom-control-label {
    margin-top: 0 !important;
  }
}
.color-picker-input {
  .color-picker {
    height: 36px;
    width: 60px;
    border: transparent;
    background: transparent;
  }
  .input-group-text {
    padding: 0;
  }
  .form-control {
    border-right: none;
  }
}
</style>>
